<template>
    <div>
        <div class="row">
            <div class="col-md-7">
                <div class="row">
                    <div class="col-md-6 py_5px">
                        <div class="label_box">
                            <img
                                style="height: 90px"
                                src="../../../assets/rb_44645.png"
                            />
                            <div style="padding-left: 1rem; width: 100%">
                                <p>ยอดขายสินค้า</p>
                                <h2 class="text_data">{{ amount }} บาท</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 py_5px">
                        <div class="label_box">
                            <img
                                style="height: 90px"
                                src="../../../assets/rb_44646.png"
                            />
                            <div style="padding-left: 1rem; width: 100%">
                                <p>ยอดสั่งจองสินค้า</p>
                                <h2 class="text_data">{{ num }} ชิ้น</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 py_5px">
                        <div class="chart">
                            <h3 style="margin-bottom: 10px; margin-top: 20px;">
                                ยอดขายแยกตามผลิตภัณฑ์ (บาท)
                            </h3>
                            <highcharts
                                style="height: 600px"
                                :options="chartOptions"
                            ></highcharts>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <!-- Pie Chart Section -->
                <div class="chart" style="margin-bottom: 10px; margin-top: 0">
                    <h3 style="margin-bottom: 10px">สัดส่วนยอดขายแยกตามประเภทของผลิตภัณฑ์</h3>
                    <highcharts style="height: 280px" :options="pieOption"></highcharts>
                </div>
                <div class="chart" style="margin-top: 0">
                    <h3 style="margin-bottom: 10px">
                        รายการขายผลิตภัณฑ์
                    </h3>
                    <div class="table-container">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>ชุมชน</th>
                                    <th>ผลิตภัณฑ์</th>
                                    <th>จํานวน</th>
                                    <th>ยอดขาย (บาท)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in tableData" :key="index">
                                    <td>{{ item.community }}</td>
                                    <td>{{ item.product }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.sales.toLocaleString() }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import axios from "axios";

Highcharts.setOptions({
    lang: {
        decimalPoint: ".",
        thousandsSep: ",",
    },
});
export default {
    name: "ColumnChart",
    components: {
        highcharts: HighchartsVue.component,
    },
    data() {
        return {
            tableData: [],
            chartOptions: {
                chart: {
                    type: "column",
                },
                title: {
                    text: "",
                },
                xAxis: {
                    type: "category",
                    labels: {
                        autoRotation: [-45, -90],
                    },
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "ยอดขาย (บาท)",
                    },
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    pointFormat: "ยอดขาย: <b>{point.y} บาท</b>",
                },
                series: [
                    {
                        name: "Population",
                        colors: [
                            "#EB5B00",
                            "#FFB200",
                            "#B60071",
                            "#FF4C4C",
                            "#FFB22C",
                            "#FFDE4D",
                            "#FFAD60",
                            "#FFBF78",
                            "#FEB941",
                        ],
                        colorByPoint: true,
                        groupPadding: 0,
                        data: [],
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                return this.y?.toLocaleString() + " บาท";
                            },
                        },
                    },
                ],
                exporting: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
            },

            pieOption: {
                chart: {
                    type: "pie",
                },
                title: {
                    text: "",
                },
                tooltip: {
                    pointFormat:
                        "{series.name}: <b>{point.y} บาท </b> <br/> คิดเป็น: <b>{point.percentage:.1f}%</b>",
                },
                plotOptions: {
                    series: {
                        allowPointSelect: true,
                        cursor: "pointer",
                    },
                    pie: {
                        showInLegend: true,
                    },
                },
                series: [
                    {
                        name: "ยอดขาย",
                        colors: [
                            "#EB5B00",
                            "#FFB200",
                            "#B60071",
                            "#FF4C4C",
                            "#FFB22C",
                            "#FFDE4D",
                            "#FFAD60",
                            "#FFBF78",
                            "#FEB941",
                        ],
                        colorByPoint: true,
                        data: [],
                    },
                ],
                exporting: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
            },
            amount: "0",
            num: "0",
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const community_id = localStorage.getItem("community_id");
                const role_id = localStorage.getItem("role_id");
                const apiData = [];
                if (role_id == 1) {
                    const res = await axios.get(
                        "https://openapi.kims-rmuti.com/api/ProductCommition"
                    );
                    apiData.push(...res.data);
                } else {
                    const res = await axios.get(
                        `https://openapi.kims-rmuti.com/api/ProductCommition?community=${community_id}`
                    );
                    apiData.push(...res.data);
                }
                this.tableData = apiData.map((item) => ({
                    community: item.community_name || "ไม่ระบุ",
                    product: item.product_name || "ไม่ระบุ",
                    quantity: item.product_quantity_sum || 0,
                    sales: parseInt(item.total_price_sum) || 0,
                }));
                const groupedData = apiData.reduce((acc, item) => {
                    const groupName = item.product_group_name || "ไม่ระบุ";
                    const price = parseInt(item.total_price_sum) || 0;

                    if (!acc[groupName]) {
                        acc[groupName] = { name: groupName, y: 0 };
                    }
                    acc[groupName].y += price;
                    return acc;
                }, {});
                this.pieOption.series[0].data = Object.values(groupedData);
                const sort_data = apiData.sort(
                    (a, b) => b.total_price_sum - a.total_price_sum
                );
                let total = 0;
                const categories = sort_data.map((item) => item.product_name);
                const values = sort_data.map((item) => {
                    total += parseInt(item.total_price_sum);
                    return parseInt(item.total_price_sum);
                });
                this.chartOptions.xAxis.categories = categories;
                this.chartOptions.series[0].data = values;
                const result = Object.values(groupedData);
                this.pieOption.series[0].data = result;

                this.amount = total?.toLocaleString();

                if (role_id == 1) {
                    const res = await axios.get(
                        "https://openapi.kims-rmuti.com/api/Reservation"
                    );
                    apiData.push(...res.data);
                } else {
                    const res = await axios.get(
                        `https://openapi.kims-rmuti.com/api/Reservation?community=${community_id}`
                    );
                    apiData.push(...res.data);
                }
                let total_num = 0;
                apiData.map((item) => {
                    total_num += parseInt(item?.quantity_reserved)
                        ? parseInt(item?.quantity_reserved)
                        : 0;
                });

                this.num = total_num?.toLocaleString();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        calculatePercentage(value) {
            const total = this.pieOption.series[0].data.reduce(
                (sum, item) => sum + item.y,
                0
            );
            return (value / total) * 100;
        },
    },
};
</script>

<style scoped>
.top-banner {
    position: relative;
    background-color: #f9f9f9;
    padding: 40px;
}
.line_blue {
    margin-top: 13px !important;
    margin-bottom: 20px !important;
    border: 0 !important;
    border-top: 2px solid #206ab0 !important;
}
h3 {
    font-size: 20px;
    font-weight: 500;
    text-align: start;
}

.text_data {
    margin-top: 1rem;
    font-size: 34px;
    font-weight: 600;
    text-align: center;
}

.label_box {
    display: flex;
    background-color: #ffffff;
    padding: 25px 25px 25px 25px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    box-shadow: 1px 1px 5px #e3e3e3;
}
.label_box p {
    font-size: 16px;
}

.chart {
    margin-top: 10px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #e3e3e3;
}

.py_5px {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.table-container {
    max-height: 400px;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.table {
    margin: 0;
    width: 100%;
}

.table th {
    white-space: nowrap;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
}
</style>
